
<template>
  <div>
     <user-email-verification v-if="activeUser && !activeUser.is_verified" />

    <div class="vx-row">

      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <statistics-card-line
          icon="DownloadCloudIcon"
          :hideChart="true"
          :statistic="orderSummary.pending | k_formatter"
          statisticTitle="Pending Orders"
          color='success'
          type='area' />
      </div>

      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <statistics-card-line
          icon="SendIcon"
          :hideChart="true"
          :statistic="orderSummary.delivered | k_formatter"
          statisticTitle="Delivered Orders"
          color='danger'
          type='area' />
      </div>
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <statistics-card-line
          icon="ActivityIcon"
          :hideChart="true"
          :statistic="orderSummary.completed | k_formatter"
          statisticTitle="Completed Orders"
          color='warning'
          type='area' />
      </div>
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 xl:w-1/4 mb-base">
        <statistics-card-line
          icon="RotateCcwIcon"
          :hideChart="true"
          :statistic="orderSummary.refunded | k_formatter"
          statisticTitle="Refunded Orders"
          type='area' />
      </div>
    </div>
    <div class="vx-row" v-if="dataIsParseable">
        <div class="vx-col w-full mb-base">
            <vx-card title="Payment Statistics">
                <div>
                    <div class="flex justify-between">
                        <div class="flex flex-col">
                            <span class="mb-1">In Percentage</span>
                            <h4>{{ getPaidPercentage }}%</h4>
                        </div>
                        <div class="flex flex-col text-right">
                            <span class="flex -mr-1">
                                <span class="mr-1">{{ orderSummary.paid }}</span>
                                <feather-icon icon="ArrowUpIcon" svgClasses="text-success stroke-current h-4 w-4 mb-1 mr-1"></feather-icon>
                            </span>
                            <span class="flex -mr-1">
                              <span class="text-grey mr-1">{{ orderSummary.unpaid }}</span>
                              <feather-icon icon="ArrowDownIcon" svgClasses="text-danger stroke-current h-4 w-4 mb-1 mr-1"></feather-icon>
                            </span>
                        </div>
                    </div>
                    <vs-progress :percent="getPaidPercentage"></vs-progress>
                </div>
                <vs-alert color="primary" class="mt-3" title="Summary">
                  <span>Dear worker, out of a total of {{orderSummary.total}} order{{orderSummary.total>1? 's': ''}}. {{orderSummary.paid}} {{orderSummary.paid>1? 'are': 'is'}} paid,  {{orderSummary.unpaid}} unpaid and {{orderSummary.refunded}} refunded. Thanks </span>
                </vs-alert >
            </vx-card>
        </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full">
        <order-list />
      </div>
    </div>
  </div>
</template>
<script>

import OrderList from './orders/OrderList.vue'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import UserEmailVerification    from "./account/UserEmailVerification.vue"

export default{
    components: {
      StatisticsCardLine,
      OrderList,
      UserEmailVerification
    },
    data() {
      return {
        dataIsParseable: false,
        orderSummary:{
          total: 0,
          pending:0,
          delivered:0,
          completed:0,
          refunded:0,
          paid:0,
          unpaid:0
        }
      }
    },
    computed: {
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      getPaidPercentage(){
        let val = Math.round((this.orderSummary.paid/(this.orderSummary.paid+this.orderSummary.unpaid))*100)
        return val ? val : 0
      }
    },
    created() {
     this.$http.get(`/analytics`)
      .then((response) => {
        if(response.data.success){
          var result = response.data.result
          this.orderSummary = {
            total: result.orders.total,
            pending: result.orders.pending,
            delivered: result.orders.delivered,
            completed: result.orders.completed,
            refunded: result.orders.refunded,
            paid: result.orders.paid,
            unpaid: result.orders.unpaid
          }
          this.dataIsParseable = true
        }else{
          this.alertError(response.data.error)
        }
      }).catch((error) => { console.log(error) })
    }
}
</script>

<style lang="scss">
.chat-card-log {
    height: 400px;

    .chat-sent-msg {
        background-color: #f2f4f7 !important;
    }
}
.vx-card.system-user-chart .vx-card__body{
  padding-bottom:5px;
}
</style>
